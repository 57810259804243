@mixin button($padding: 6px 10px, $font-size: 12px, $border-radius: 4px, $bg-color: #ffffff, $font-color: #000000) {
  padding: $padding;
  display: inline-block;
  border: none;
  border-radius: $border-radius;
  background: $bg-color;
  font-size: $font-size;
  color: $font-color;
  cursor: pointer;
}

.MuiTableRow-root .MuiIconButton-root.Mui-disabled {
  visibility: hidden;
  display: none;
}

.MD100 .MuiTableRow-root .MuiTableCell-root:first-child {
  display: none;
  width: 0;
}

._loading_overlay_content {
  > div {
    margin: 0 auto;
  }
}

#map {
  display: flex;
  flex: 1 1 auto;
  // height: 400px;
  height: 51vh;

  &.dark {
    .details,
    .leaflet-popup-tip,
    .leaflet-popup-content-wrapper {
      background-color: #1E1E1E;
      color: #ffffff;
    }

    .leaflet-popup-content {
      button {
        @include button;
      }
    }
  }

  &.light {
    .details,
    .leaflet-popup-tip,
    .leaflet-popup-content-wrapper {
      background-color: #ffffff;
      color: #000000;
    }

    .leaflet-popup-content {
      button {
        @include button(6px 10px, 12px, 4px, #5945cc, #ffffff);
      }
    }
  }
}

.details {
  padding: 15px;

  ul {
    text-align: left;
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .directional {
    display: flex;

    .direction-title {
      border-bottom: solid 1px;
    }

    .direction-1 {
      margin-right: 10px;
    }
  }
}

// .layermapcontrol {
//   // position: relative;
//   // padding: 12px;
//   margin-top: 525px;
//   // margin-bottom: 75px;
// }
// .zoommapcontrol {
//   // position: relative;
//   // padding: 12px;
//   margin-top: 225px;
//   // margin-bottom: 75px;
// }
.resetmapcontrol {
  position: relative;
  // padding: 12px;
  // margin-top: 75px;
  // margin-bottom: 75px;
margin-right: 50px;
  z-index:5000;
}
// .filtermapcontrol {
//   // padding: 12px;
//   padding-top: 50px;
// }

.current {
  padding: 8px 16px;
}

.logo-wrapper {
  margin-top: auto !important;
}

.logo {
  height: 37px;
  width: 130px;
  padding: 0 16px;
}

.logo-tti {
  height: 37px;
  width: 130px;
  padding: 0 4px;
}

.link-disabled {
  text-decoration: line-through !important;
}

.leaflet-container {
  width: 100%;
}

.home-button {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  margin-left: 0 !important;
  padding: 3px !important;
}

.table-wrap,
.table-null {
  margin-bottom: 0 !important;
}

.table-null {
  padding: 16px;
}

.custom-block {
  font-size: 0.75rem;
}
.custom-block .custom-block-title {
    font-weight: 600;
    margin-bottom: -.4rem
}

.from-to-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
